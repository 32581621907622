import React from "react"
import { Link } from "gatsby"
import { BlogPostsList } from "./BlogPostsList"


const BlogCard = ({ allPost, pageInfo }) => {

  const totalNumPages = pageInfo.pageCount
  const currentPage = pageInfo.currentPage

  const isFirst = currentPage === 1
  const isLast = currentPage === totalNumPages
  const prevPage = currentPage - 1 === 1 ? "/blog/" : "/blog/" + (currentPage - 1).toString() + '/'
  const nextPage = "/blog/" + (currentPage + 1).toString() + '/'


  return (
    <div className="blog-area pt-100">
      <div className="container">
        <div className="row">

          {
            allPost.map(({ id, ...otherBlogProps }) => (
                <BlogPostsList key={id} {...otherBlogProps} />
              )
            )
          }

          <div className="col-lg-12 col-md-12">
            {/* Pagination */}
            <div className="pagination-area text-center">
              {!isFirst && (
                <Link to={prevPage} className="prev page-numbers" rel="prev">
                  <i className="bx bx-chevrons-left" />
                </Link>
              )}
              {Array.from({ length: totalNumPages }, (_, i) => (
                <Link key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}/`}
                      className={`page-numbers ${currentPage - 1 === i ? "current" : ""}`}>
                  {i + 1}
                </Link>
              ))}
              {!isLast && (
                <Link to={nextPage} className="next page-numbers" rel="next">
                  <i className="bx bx-chevrons-right" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default BlogCard