import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogLayout from "../components/BlogContent/BlogLayout"
import SEO from "../components/App/SEO"


const BlogCategories = ({ data }) => {
  const postsByCategory = data.postsByCategory.nodes
  const pageInfo = data.postsByCategory.pageInfo
  return (
    <Layout>
      <SEO title={`Blog - Sitebunker.ro - ${postsByCategory[0].category.name}`} />
      <Navbar />
      <BlogLayout pageTitle={postsByCategory[0].category.name} allPost={postsByCategory} pageInfo={pageInfo} />
      <Footer />
    </Layout>
  )
}


export const query = graphql`
query GetAllPostsByCategory($slug: String!){
  postsByCategory: allStrapiBlog(filter: {category: {slug: {eq: $slug}}}){
    nodes{
      id
      title
      author
      slug
      updated_at(formatString: "D MMMM, YYYY", locale: "ro")
      category{
        name
        slug
      }
      image {
         childImageSharp {
           fixed {
             src
           }
         }
      }
    }
    pageInfo {
          currentPage
          pageCount
          totalCount
        }
  }
}
`

export default BlogCategories
