import React from "react"
import { Link } from "gatsby"

export const BlogPostsList = ({ image, author, updated_at, title, slug }) => {
  const postImage = image.childImageSharp.fixed.src
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-blog-post">
        <div className="post-image">
          <Link to={`/${slug}/`}>
            <img src={postImage} className="img-fluid blog-img" alt="Post Image" loading="lazy"  />
          </Link>
        </div>

        <div className="post-content">
          <ul className="post-meta d-flex justify-content-between align-items-center">
            <li>
              <div className="post-author d-flex align-items-center">
                <span className="author-name">{author}</span>
              </div>
            </li>
            <li>
              <i className="flaticon-calendar date-icon" />
              <span className="date-post text-capitalize">
                {updated_at}
              </span>

            </li>
          </ul>
          <h3>
            <Link to={`/${slug}/`} className="p-medium-small blog-link">
              {title.length > 50 ? title.slice(0, 50) + "..." : title}
            </Link>

          </h3>
        </div>
      </div>
    </div>
  )
}
