import React from "react"
import BlogCard from "./BlogCard"

const BlogLayout = ({allPost, pageInfo, pageTitle, pageDescription}) => {
  return (
  <div>
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content text-left">
          <h2 className="mb-4">{pageTitle}</h2>
          <div className="row">
            <div className="col-md-9 col-12">
              {pageDescription !== undefined ? <p>{pageDescription}</p> : ""}
            </div>
          </div>
        </div>
        <BlogCard allPost={allPost} pageInfo={pageInfo} />
      </div>
    </div>
  </div>
  )
}

export default BlogLayout